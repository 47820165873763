import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import styles from "./news.module.css";
import CloseIconWhite from "../../assets/icons/close-white.svg";

const NewsDetail = ({ selected, ImagePlaceholder }) => (
  <div className="modal-content border-0 overflow-hidden rounded-0">
    <button
      type="button"
      data-bs-dismiss="modal"
      aria-label="Close"
      className={`btn-close ${styles["close-btn"]} position-absolute`}
    >
      <img alt="Close" src={CloseIconWhite} className="w-100" />
    </button>
    {selected && (
      <>
        <LazyLoadImage
          alt={selected.title}
          src={selected.image ? selected.image : ImagePlaceholder}
          width={"100%"}
          effect="blur"
          wrapperClassName="d-flex"
          className={styles["modal-img"]}
        />
        <div className="modal-body">
          <h5 className="modal-title mb-3" id="newsModalLabel">
            {selected && selected.title}
          </h5>
          {selected && selected.content}
        </div>
      </>
    )}
  </div>
);

export default NewsDetail;
