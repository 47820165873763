// El orden en el que aparecen los tracks en la lista representa el orden en el que aparecerán en la playlist.
export const playlist = [
  {
    id: 17,
    title: "VIAE, for flute, viola and harp",
    track: require("../assets/works/catalog/viae/viae.mp3"),
  },
 
  {
    id: 16,
    title: "Gigue, for guitar",
    track: require("../assets/works/catalog/guitarsuite/giga.mp3"),
  },
  {
    id: 14,
    title: "Masa, for mixed choir",
    track: require("../assets/works/catalog/Masa/Masa.mp3"),
  },
  {
    id: 4,
    title: "Piano Trio Nº1: I",
    track: require("../assets/works/catalog/pianotrio1/Piano Trio 1 - I.mp3"),
  },
  {
    id: 5,
    title: "Piano Trio Nº1: II",
    track: require("../assets/works/catalog/pianotrio1/Piano Trio 1 - II.mp3"),
  },
  {
    id: 6,
    title: "Piano Trio Nº1: III",
    track: require("../assets/works/catalog/pianotrio1/Piano Trio 1 - III.mp3"),
  },
  {
    id: 15,
    title: "Crepuscular, for equal voices",
    track: require("../assets/works/catalog/crepuscular/crepuscular.mp3"),
  },
  {
    id: 7,
    title: "Dos Danzas: Danza Costeña",
    track: require("../assets/works/catalog/dosdanzas/I. Danza costeña.mp3"),
  },
  {
    id: 8,
    title: "Dos Danzas: Danza Andina",
    track: require("../assets/works/catalog/dosdanzas/II. Danza andina.mp3"),
  },
  {
    id: 9,
    title: "Dos Crisoles",
    track: require("../assets/works/catalog/doscrisoles/2 crisoles (Sextet version).mp3"),
  },
  {
    id: 10,
    title: "Soliloquia inter memorias",
    track: require("../assets/works/catalog/soliloquium/soli2.mp3"),
  },
  {
    id: 11,
    title: "Nuevas Escenas: I",
    track: require("../assets/works/catalog/nuevasescenas/Nuevas Escenas I.mp3"),
  },
  {
    id: 12,
    title: "Nuevas Escenas: II",
    track: require("../assets/works/catalog/nuevasescenas/Nuevas Escenas II.mp3"),
  },
  {
    id: 13,
    title: "Nuevas Escenas: III",
    track: require("../assets/works/catalog/nuevasescenas/Nuevas Escenas III.mp3"),
  },
  {
    id: 3,
    title: "Tres Poemas de Alejandra Pizarnik (I, II, III)",
    track: require("../assets/works/catalog/pizarnik/Tres poemas Pizarnik - AliagaDelBosque.mp3"),
  },
  {
    id: 1,
    title: "Escenas: I",
    track: require("../assets/works/catalog/escenas/E1.mp3"),
  },
  {
    id: 2,
    title: "Escenas: II",
    track: require("../assets/works/catalog/escenas/E2.mp3"),
  },
];

const data = {
  playlist,
};

export default data;
