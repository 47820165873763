import React, { useState, useEffect, useRef, createRef } from "react";
import { ref, onValue, query, orderByChild, equalTo } from "firebase/database";
import db from "../../database";

import PageContainer from "../common/PageContainer";
import PageTitle from "../common/PageTitle";
import { GENRES } from "../../utils/constants";
import AudioAnimation from "../Player/AudioAnimation";
import PlayIcon from "../../assets/icons/play.svg";
import PauseIcon from "../../assets/icons/pause.svg";
import { ReactComponent as MoreIcon } from "../../assets/icons/add-square-svgrepo-com-transparent.svg";
import { ReactComponent as Youtube } from "../../assets/icons/movie.svg";
import styles from "./works.module.css";

const Works = ({
  playing,
  currentTrackID,
  selectTrack,
  pauseSelectedTrack,
  showPlayer,
  setShowPlayer,
}) => {
  const [filter, setFilter] = useState("All");
  const [filteredWorks, setFilteredWorks] = useState([]);
  const [hovering, setHovering] = useState(false);
  const [works, setWorks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleDescriptions, setVisibleDescriptions] = useState([]);
  const worksOverlayRefs = useRef([]);

  const fetchWorks = () => {
    const worksRef = query(
      ref(db, `works`),
      orderByChild("published"),
      equalTo(true)
    );
    onValue(worksRef, async (snapshot) => {
      const data = await snapshot.val();
      if (data === null) {
        setWorks(data);
      } else {
        const isArray = Array.isArray(data);
        const dataArr = isArray ? data : Object.values(data);
        const filteredData = dataArr.filter((item) => !!item);
        setWorks(filteredData);
        setFilteredWorks(filteredData);
        worksOverlayRefs.current = filteredData.map(
          (_, i) => worksOverlayRefs.current[i] ?? createRef()
        );
      }
      setLoading(false);

      return data;
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchWorks();
  }, []);

  const filterWorks = (filter) => {
    // Clear filtered array
    setFilteredWorks([]);
    setTimeout(() => {
      if (filter === "All") {
        setFilteredWorks(works);
      } else {
        const filtered = works.filter((work) => work.genre === filter);
        setFilteredWorks(filtered);
      }
    }, 0);
  };

  const toggleDescription = (itemId, element) => {
    const isVisible = visibleDescriptions.includes(itemId);
    console.log(element.current);
    // Add to visible descriptions array
    if (!isVisible) {
      const newVisibleDescriptions = [...visibleDescriptions, itemId];
      setVisibleDescriptions(newVisibleDescriptions);
      setTimeout(() => {
        element.current.classList.add(styles["overflowed"]);
      }, 250);
    } else {
      const newVisibleDescriptions = visibleDescriptions.filter(
        (id) => id !== itemId
      );
      setVisibleDescriptions(newVisibleDescriptions);
      element.current.classList.remove(styles["overflowed"]);
    }
  };

  const getDescription = (item) =>
    item.description.split("\\n").map((itemP, i) => (
      <p key={`${item.title}-${i}`} className="d-block small">
        {itemP.match(/[^\]]+(?![^<]*\])/g)}
      </p>
    ));

  return (
    <PageContainer>
      <PageTitle title="Works" />
      <div className="row">
        <div className="col-12 col-md-6">
          <select
            className="form-select form-select-sm border-0 mb-3"
            aria-label="Genre select"
            disabled={loading}
            value={filter}
            onChange={(event) => {
              setFilter(event.target.value);
              filterWorks(event.target.value);
            }}
          >
            <option value="All" key="filter-All">
              All
            </option>
            {GENRES.map((genre) => (
              <option value={genre.value} key={`filter-${genre.value}`}>
                {genre.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row">
        {filteredWorks.map((item, i) => {
          const isVisible = visibleDescriptions.includes(item.id);
          return (
            <div
              className={`d-flex col-12 col-md-6 mb-4 ${styles["work-card"]}`}
              key={`works-${item.title}`}
            >
              <div className="card flex-fill border-0 overflow-hidden">
                {item.description && (
                  <button
                    className={`${styles["info-button"]} my-2 d-flex align-items-center`}
                    onClick={() => {
                      toggleDescription(item.id, worksOverlayRefs.current[i]);
                    }}
                  >
                    {
                      <span className={isVisible ? styles["visible"] : ""}>
                        info
                      </span>
                    }
                    <MoreIcon
                      className={`${styles["card-header-button"]} ${
                        isVisible ? styles["visible"] : ""
                      }`}
                    />
                  </button>
                )}
                <div className="card-body pt-2 pe-2">
                  <div
                    className={`${styles["card-header"]} d-flex align-items-center justify-content-between`}
                  >
                    <h6 className="m-0">{item.title}</h6>
                  </div>
                  <div className="position-relative">
                    {/* Overlay element */}
                    {item.description && (
                      <div
                        ref={worksOverlayRefs.current[i]}
                        className={`${styles["overlay-card"]} ${
                          isVisible ? styles["visible"] : ""
                        } position-absolute pe-1`}
                      >
                        {getDescription(item)}
                      </div>
                    )}
                    {item.instrumentation && (
                      <span className="d-block small">
                        for {item.instrumentation}
                      </span>
                    )}
                    {item.year && (
                      <span className="d-block small">{item.year}</span>
                    )}
                    {item.duration && (
                      <span className="d-block small">
                        Duration: {item.duration}
                      </span>
                    )}
                    <div className="d-flex align-items-center">
                      {item.trackID && (
                        <>
                          {playing && item.trackID.includes(currentTrackID) ? (
                            <div
                              className="work-playing-icon"
                              onMouseEnter={() => setHovering(true)}
                              onMouseLeave={() => setHovering(false)}
                            >
                              {hovering ? (
                                <button
                                  className={`${styles["play-button"]} mt-2`}
                                  onClick={() => {
                                    pauseSelectedTrack();
                                  }}
                                >
                                  <img src={PauseIcon} alt="pause icon" />
                                </button>
                              ) : (
                                <AudioAnimation />
                              )}
                            </div>
                          ) : (
                            <button
                              className={`${styles["play-button"]} mt-2`}
                              onClick={() => {
                                if (!showPlayer) {
                                  setShowPlayer();
                                }
                                selectTrack(item.trackID);
                              }}
                            >
                              <img src={PlayIcon} alt="play icon" />
                            </button>
                          )}
                        </>
                      )}
                      {item.video && (
                        <a href={item.video} target="_blank" rel="noreferrer">
                          <Youtube
                            className={`mt-2 ${styles["social-icon"]}`}
                          />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </PageContainer>
  );
};

export default Works;
