import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styles from "./news.module.css";

import ImagePlaceholder from "../../assets/Aliaga_Home.jpg";

const NewsHighlight = ({ highlight, isSmall, isExtraLarge }) => {
  return (
    <div
      className={`card w-100 border-0 rounded-0 overflow-hidden mb-3 ${styles["news-highlight"]}`}
    >
      {highlight && (
        <div className="row flex-fill">
          <div className="col-md-6 d-flex align-items-stretch">
            <LazyLoadImage
              alt={highlight.title}
              src={highlight.image ? highlight.image : ImagePlaceholder}
              width={"100%"}
              effect="blur"
              wrapperClassName="d-flex"
              className="object-fit-cover"
              style={{
                objectPosition: isSmall
                  ? `${
                      !!highlight.imageHorizontalPositionSm
                        ? highlight.imageHorizontalPositionSm
                        : "center"
                    } ${
                      !!highlight.imageVerticalPositionSm
                        ? highlight.imageVerticalPositionSm
                        : "center"
                    }`
                  : isExtraLarge
                  ? `${
                      !!highlight.imageHorizontalPositionXl
                        ? highlight.imageHorizontalPositionXl
                        : "center"
                    } ${
                      !!highlight.imageVerticalPositionXl
                        ? highlight.imageVerticalPositionXl
                        : "center"
                    }`
                  : `${
                      !!highlight.imageHorizontalPosition
                        ? highlight.imageHorizontalPosition
                        : "center"
                    } ${
                      !!highlight.imageVerticalPosition
                        ? highlight.imageVerticalPosition
                        : "center"
                    }`,
              }}
            />
          </div>
          <div className="col-md-6 card-body px-4">
            <h4>{highlight.title}</h4>
            {highlight?.content}
            <span className="small text-muted">{highlight.date}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewsHighlight;
